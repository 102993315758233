<template>
  <div class="admin">
    <div class="login" v-if="!user">
      <b-form @submit="onSubmit">
        <b-form-group id="input-group-1" label="Email address:" label-for="input-1">
          <b-form-input id="input-1" v-model="form.email" type="email" required placeholder="Enter email"></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-2" label="Password:" label-for="input-2">
          <b-form-input id="input-2" v-model="form.password" type="password" required placeholder="Enter password"></b-form-input>
        </b-form-group>

        <b-button type="submit" variant="primary">Login</b-button>
      </b-form>
    </div>
    <div v-else>
      <b-row>
        <b-button type="button" @click="$store.dispatch('Admin/logout')" variant="danger">Logout</b-button>
      </b-row>

      <hr/>

      <div class="editor" v-if="editor">
        <b-form>
          <b-form-group id="input-group-3" label="Category" label-for="input-3">
            <b-form-input id="input-3" v-model="question.category" type="text" required placeholder="Enter category"></b-form-input>
          </b-form-group>
          <b-form-group id="input-group-4" label="Question" label-for="input-4">
            <b-form-textarea id="input-4" rows="5" v-model="question.question" type="text" required placeholder="Enter question"></b-form-textarea>
          </b-form-group>
          <b-form-group id="input-group-5" label="Source" label-for="input-5">
            <b-form-input id="input-5" v-model="question.source" type="text" required placeholder="Enter source"></b-form-input>
          </b-form-group>
          <b-form-group id="input-group-6" label="Good Answer" label-for="input-6">
            <b-form-input id="input-6" v-model="question.answers[0].value" type="text" required placeholder="Good Answer"></b-form-input>
          </b-form-group>
          <b-form-group id="input-group-7" label="Bad Answer" label-for="input-7">
            <b-form-input id="input-7" v-model="question.answers[1].value" type="text" required placeholder="Bad Answer"></b-form-input>
          </b-form-group>

          <div v-if="!question.id">
            <b-button type="button" class="float-right" @click="save" variant="primary">Save</b-button>
            <b-button type="button" style="margin-right: 15px;" class="float-right" @click="cancel" variant="secondary">Cancel</b-button>
          </div>
          <div v-else>
            <b-button type="button" @click="remove" variant="danger">Delete</b-button>
            <b-button type="button" class="float-right" @click="update" variant="primary">Save</b-button>
            <b-button type="button" style="margin-right: 15px;" class="float-right" @click="cancel" variant="secondary">Cancel</b-button>
          </div>
        </b-form>
      </div>
      <div v-else>
        <h4>Total: {{ questions.length }} <i style="cursor: pointer;margin-left: 5px;" @click="$store.dispatch('Questions/loadQuestions')" class="fas fa-sync"></i>
          <b-form-input style="max-width: 205px;display: inline-block;margin-left: 15px;" id="input-8" v-model="searchKey" placeholder="Search"></b-form-input>
          <b-button v-if="!editor" type="button" class="float-right" @click="add" variant="primary">+ Add</b-button>
        </h4>

        <table class="table table-sm table-hover table-striped table-dark">
          <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col">Category</th>
            <th scope="col">Question</th>
            <th scope="col">Answers</th>
            <th class="text-center" scope="col">Source</th>
            <th scope="col">Last modify</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="q in filteredQuestions" :key="q.id">
            <td class="align-middle text-center" style="cursor: pointer" @click="edit(q)"><i class="fas fa-edit"></i></td>
            <td class="align-middle">{{ q.category }}</td>
            <td class="align-middle">{{ q.question }}</td>
            <td class="align-middle">
              <p v-for="(a, idx) in q.answers" :key="idx" :class="a.correct ? 'text-success': 'text-danger'">{{ a.value }}</p>
            </td>
            <td class="text-center align-middle">
              <a :href="q.source" target="_blank" :title="q.source"><i class="fas fa-link"></i></a>
            </td>
            <td class="align-middle">
              <p>{{ (q.updated || q.created) | moment("YYYY-MM-DD HH:mm") }}</p>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import Vue from 'vue'

export default {
	name: 'App',
	data () {
		return {
			form: {
				email: '',
				password: ''
			},
			searchKey: '',
			question: {
				category: '',
				question: '',
				source: '',
				answers: [
					{
						correct: true,
						value: ''
					},
					{
						correct: false,
						value: ''
					}
				]
			},
			editor: null
		}
	},
	components: {},
	computed: {
		...mapState('Questions', [
			'questions'
		]),
		...mapState('Admin', [
			'user'
		]),
		filteredQuestions () {
			return this.questions.filter((q) => {
				return JSON.stringify(q).toLowerCase().includes(this.searchKey.toLowerCase())
			})
		}
	},
	methods: {
		onSubmit (e) {
			e.preventDefault()
			this.$store.dispatch('Admin/login', this.form)
		},
		add () {
			this.editor = true
			this.setDefault()
		},
		setDefault () {
			this.question = {
				category: '',
				question: '',
				source: '',
				answers: [
					{
						correct: true,
						value: ''
					},
					{
						correct: false,
						value: ''
					}
				],
				created: new Date().getTime(),
				updated: null
			}
		},
		save () {
			if (this.validate()) {
				console.log(this.question)
				this.$store.dispatch('Questions/saveQuestion', this.question).then(() => {
					Vue.$toast.success('Question added.')
					this.setDefault()
				})
			} else {
				alert('Fill the form')
			}
		},
		update () {
			if (this.validate() && this.question.id) {
				this.$store.dispatch('Questions/updateQuestion', this.question).then(() => {
					Vue.$toast.success('Question updated.')
					this.cancel()
				})
			} else {
				alert('Fill the form')
			}
		},
		remove () {
			if (!this.question.id) {
				alert('Select a question')
			} else {
				let confirm = window.confirm('You are about to delete the question. Are you sure?')
				if (confirm) {
					this.$store.dispatch('Questions/deleteQuestion', this.question).then(() => {
						this.cancel()
						Vue.$toast.success('Question removed.')
					})
				}
			}
		},
		validate () {
			return !!(this.question.category && this.question.question && this.question.source && this.question.answers[0].value && this.question.answers[1].value);
		},
		cancel () {
			this.editor = null
			this.setDefault()
		},
		edit (question) {
			this.editor = true
			this.question = {
				id: question.id,
				category: question.category,
				question: question.question,
				source: question.source,
				answers: [
					{
						correct: true,
						value: question.answers[0].value
					},
					{
						correct: false,
						value: question.answers[1].value
					}
				],
				created: question.created,
				updated: new Date().getTime()
			}
		}
	}
}
</script>